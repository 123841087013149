import './Construction.scss';

const UnderConstruction = () => (
  <div className="under-construction">
    <h1>Under Construction</h1>
    <p>We're working hard to finish the development of this site. Stay tuned!</p>
  </div>
);

export default UnderConstruction;
