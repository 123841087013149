import { NavLink } from "react-router-dom";
import "./HomePage.scss";
import Button from "@mui/material/Button";

export default function HomePage() {
  return (
    <div className="home">
      <div className="home__hero">
        <h1 className="home__title">Health 4 Longevity</h1>
        <h2 className="home__subtitle">Exclusive Membership Benefits </h2>
        <div to="/membership" className="home__button">
          <Button
            href="/membership"
            variant="contained"
            size="medium"
            disableElevation
            sx={{
              padding: "1rem 2rem",
              fontFamily: "Glacial Indifference",
              fontSize: "1rem",
              letterSpacing: "0.25rem",
              backgroundColor: "#3a9e9e",
              color: "white",
              "&:hover": {
                backgroundColor: "#FFDE89",
              },
            }}
          >
            Join Now
          </Button>
        </div>
      </div>
    </div>
  );
}
