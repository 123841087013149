import React from "react";
import "./Footer.scss";
import { NavLink } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LOGO from "../../assets/images/LOGO.png";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer_top">
        <div className="footer_column footer_column--first">
          <NavLink to="/" className="footer_link">
            <img className="footer_logo" src={LOGO} />
          </NavLink>
        </div>

        <div className="footer_column">
          <h4 className="footer_header">Site Map</h4>
          <div className="footer_columns">
            <div className="footer_column-one">
              <NavLink to="/">Home</NavLink>
              <NavLink to="/about-us">About</NavLink>
              <NavLink to="/events">Events</NavLink>
            </div>
            <div className="footer_column-two">
              <NavLink to="/exhibitors">Exhibitors</NavLink>
              <NavLink to="/resources">Resources</NavLink>
              <NavLink to="/memberships">Memberships</NavLink>
            </div>
          </div>
        </div>

        <div className="footer_column">
          <h4 className="footer_header">Find Us</h4>

          <p>
            #103-7609 109 St NW,
            <br /> Edmonton, AB
            <br /> T6G 1C3
            <br />
            <br /> <a href="mailto:email@example.com">email@example.com</a>
            <br /> <a href="tel:+15874807460">587-480-7460</a>
          </p>
          {/* <div className="footer_socials">
            <NavLink className="footer_link" to="https://www.facebook.com/mensvitalityservices">
              <FacebookIcon />
            </NavLink>
            <NavLink className="footer_link" to="https://www.instagram.com/mensvitalityservices/">
              <InstagramIcon />
            </NavLink>
            <NavLink className="footer_link" to="https://wa.me/message/Q5NEXLSCAFOGO1">
              <WhatsAppIcon />
            </NavLink>
          </div> */}
        </div>
      </div>
      <div className="footer_bottom">
        <h4>
          &copy; {new Date().getFullYear()} <span>Health for Longevity</span>{" "}
          All rights reserved.
        </h4>
      </div>
    </div>
  );
}
