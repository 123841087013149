import "./Membership.scss";
import FAQ from "../../component/FAQ/FAQ.js";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import ContactsIcon from "@mui/icons-material/Contacts";
import DiscountIcon from "@mui/icons-material/Discount";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import WorkIcon from "@mui/icons-material/Work";
import NewpaperIcon from "@mui/icons-material/Newspaper";
import NewsletterSubscription from "../../component/Newsletter/Newsletter";
import SignUpForm from "../Form/Form.js";
import { Button } from "@mui/material";

export default function Membership() {
  // Define the list of benefits
  const benefits = [
    {
      icon: (
        <Diversity3Icon
          className="membership__icon"
          sx={{ fontSize: 50, marginBottom: "1rem" }}
        />
      ),
      title: "Networking Opportunities",
      description:
        "Access to a global network of healthcare professionals, allowing for collaboration and sharing of best practices and innovations.",
    },
    {
      icon: (
        <ContactsIcon
          className="membership__icon"
          sx={{ fontSize: 50, marginBottom: "1rem" }}
        />
      ),
      title: "Directory Listing",
      description:
        "Inclusion in a member directory that could be accessed by the public, enhancing visibility and referrals for members' practices.",
    },
    {
      icon: (
        <DiscountIcon
          className="membership__icon"
          sx={{ fontSize: 50, marginBottom: "1rem" }}
        />
      ),
      title: "Educational Discounts",
      description:
        "Reduced rates for attending seminars, workshops, and other educational events hosted by H4L.",
    },
    {
      icon: (
        <LibraryBooksIcon
          className="membership__icon"
          sx={{ fontSize: 50, marginBottom: "1rem" }}
        />
      ),
      title: "Resource Access",
      description:
        "Access to a library of the latest research, publications, and educational materials.",
    },
    {
      icon: (
        <WorkIcon
          className="membership__icon"
          sx={{ fontSize: 50, marginBottom: "1rem" }}
        />
      ),
      title: "Advertising and Job Listings",
      description:
        "Opportunities to advertise within the H4L community, whether for job openings or health services.",
    },
    {
      icon: (
        <NewpaperIcon
          className="membership__icon"
          sx={{ fontSize: 50, marginBottom: "1rem" }}
        />
      ),
      title: "Co-Branded Communications",
      description:
        "Use of the H4L platform for members to communicate with their patients and peers through a co-branded newsletter.",
    },
    {
      icon: (
        <DiscountIcon
          className="membership__icon"
          sx={{ fontSize: 50, marginBottom: "1rem" }}
        />
      ),
      title: "Marketing Support",
      description:
        "Tools and Resources to help members market their services more effectively.",
    },
  ];

  return (
    <div className="membership">
      <div className="membership__hero">
        <h2 className="membership__subtitle">Health for Longevity</h2>
        <h1 className="membership__title">
          Exclusive Membership -{" "}
          <span className="membership__span--gold">
            <br />
            Now Free
          </span>
        </h1>
      </div>
      <div className="membership__section">
        <h2 className="membership__section--title">Membership Benefits</h2>
        <ul className="membership__list">
          {benefits.map((benefit, index) => (
            <li className="membership__list--item" key={index}>
              {benefit.icon}
              <h4 className="membership__list--title">{benefit.title}:</h4>
              <p className="membership__list--benefits">
                {benefit.description}
              </p>
            </li>
          ))}
        </ul>
        <div className="membership__signup">
          <h2 className="membership__signup--header">Ready to Join?</h2>
          <Button
            className="membership__signup--button"
            type="submit"
            variant="contained"
            href="/signup"
            sx={{
              padding: "1rem 2rem",
              fontFamily: "Glacial Indifference",
              letterSpacing: "0.25rem",
              backgroundColor: "#3a9e9e",
              color: "white",
              "&:hover": {
                backgroundColor: "#FFDE89",
              },
            }}
          >
            Sign up here
          </Button>
        </div>
      </div>
      <FAQ />
      <NewsletterSubscription />
    </div>
  );
}
