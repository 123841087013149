import React, { useState } from "react";
import axios from "axios";
import { TextField, MenuItem, Button } from "@mui/material";
import "./Form.scss";

const SignUpForm = () => {
  const [form, setForm] = useState({
    title: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  });

  const titles = [
    "M.D.",
    "D.O.",
    "MBBS",
    "DDS",
    "DMD",
    "OD",
    "DPM",
    "DC",
    "PA",
    "R.Ph.",
    "PharmD",
    "RRT",
    "CRT",
    "NRP",
    "EMT",
    "RN",
    "LPN",
    "CAA",
    "OTHER",
  ];

  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const emailIsValid = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const phoneIsValid = (phone) => /^\+\d \(\d{3}\) \d{3}-\d{4}$/.test(phone);

  const formatPhoneNumber = (inputValue) => {
    let numbers = inputValue.replace(/\D/g, ""); // remove all non-numeric characters

    // Prevent the country code "1" from being included in the format
    if (numbers.startsWith("1")) {
      numbers = numbers.slice(1);
    }

    let result = "+1 "; // default country code

    // Split into parts
    const parts = [
      numbers.slice(0, 3),
      numbers.slice(3, 6),
      numbers.slice(6, 10),
    ];

    // Append parts with formatting
    if (parts[0].length) {
      result += `(${parts[0]}`;
    }
    if (parts[1].length) {
      result += `) ${parts[1]}`;
    }
    if (parts[2].length) {
      result += `-${parts[2]}`;
    }

    return result;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "phoneNumber") {
      const stringValue = String(value); // Convert value to string to prevent errors
      const formattedNumber = formatPhoneNumber(stringValue);
      setPhoneError(
        phoneIsValid(formattedNumber)
          ? ""
          : "Please enter a valid phone number."
      );
      setForm({ ...form, [name]: formattedNumber });
    } else {
      setForm({ ...form, [name]: value });
    }
    if (name === "email") {
      setEmailError(
        emailIsValid(value) ? "" : "Please enter a valid email address."
      );
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (emailError || phoneError || !form.email || !form.phoneNumber) {
      alert("Please correct the errors before submitting.");
      return;
    }
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}members`, // Ensure this is your correct API endpoint
        form
      );
      alert("Form Submitted! Welcome to H4L!");
      // Reset the form state
      setForm({
        title: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
      });
    } catch (error) {
      console.error("Form submission failed:", error);
      // Display an error message based on the response status code or error message
      let errorMessage = "Form submission failed. Please try again later.";
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage = error.response.data.message || errorMessage;
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage = "No response from the server. Please Try Again Later"
      }
      alert(errorMessage);
    }
  };
  

  return (
    <div className="form">
      <div className="form__pic" />
      <form onSubmit={handleSubmit} className="sign-up-form">
        <h2 className="form__title">Become a Member Today</h2>
        <div className="form__name">
          <TextField
            select
            label="Title"
            name="title"
            value={form.title}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            style={{ width: "80%" }}
          >
            {titles.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="First Name"
            name="firstName"
            value={form.firstName}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
          <TextField
            label="Last Name"
            name="lastName"
            value={form.lastName}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
        </div>

        <TextField
          label="Phone Number"
          name="phoneNumber"
          value={form.phoneNumber}
          onChange={handleChange}
          error={!!phoneError}
          variant="outlined"
          fullWidth
          inputProps={{ inputMode: "numeric" }}
        />
        <TextField
          error={!!emailError}
          helperText={emailError}
          label="Email Address"
          name="email"
          value={form.email}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
        <Button
          className="form__button"
          type="submit"
          variant="contained"
          sx={{
            padding: "1rem 2rem",
            fontFamily: "Glacial Indifference",
            letterSpacing: "0.25rem",
            backgroundColor: "#3a9e9e",
            color: "white",
            "&:hover": {
              backgroundColor: "rgb(255, 222, 137)",
            },
          }}
        >
          Sign up
        </Button>
      </form>
    </div>
  );
};

export default SignUpForm;
