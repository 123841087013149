import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";

export default function MobileNavbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [visible, setVisible] = useState(window.innerWidth < 1280);

  useEffect(() => {
    const handleResize = () => {
      setVisible(window.innerWidth < 1280);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const mainLinks = [
    { to: "/", label: "Home" },
    { to: "/about-us", label: "Our Mission" },
    { to: "/construction", label: "Events" },
    { to: "/construction", label: "Exhibitors" },
    { to: "/construction", label: "Resources" },
    { to: "/membership", label: "Memberships" },
  ];
  const Legal = [
    { to: "/construction", label: "CopyRight" },
    { to: "/construction", label: "PrivatePolicy" },
  ];

  const tabletStyles = {
    drawerWidth: "50vw", // Use 50% of the viewport width for tablet drawer size
    listItemText: {
      // Apply responsive text size
    },
    // Add more styles as needed
  };

  // const socialLinks = [
  //   {
  //     label: "Facebook",
  //     to: "https://www.facebook.com/mensvitalityservices",
  //   },
  //   {
  //     label: "Instagram",
  //     to: "https://www.instagram.com/mensvitalityservices",
  //   },
  //   { label: "WhatApp", to: "https://wa.me/message/Q5NEXLSCAFOGO1" },
  //   // Add more social links as needed
  // ];

  const DrawerFooter = (props) => (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        width: "100%",
        backgroundColor: "white",
        borderTop: "1px solid #ddd",
      }}
    >
      {props.children}
    </div>
  );

  if (!visible) return null;

  return (
    <div>
      <AppBar position="static">
        <Toolbar
          style={{ backgroundColor: "white" }}
          sx={{ justifyContent: "space-around", height: "80px" }}
        >
          <Typography
            variant="h6"
            component="h4"
            sx={{
              ml: "1rem",
              fontFamily: "Noto Serif Display, serif",
              textAlign: "center",
              fontWeight: 500,
              fontSize: "1.25rem",
              textTransform: "uppercase",
              letterSpacing: "0.2rem",
              color: "white",
              "& a": {
                fontSize: "0.9rem",
                fontWeight: 300,
                color: "#3a9e95",
                fontFamily: "Helvetica, sans-serif",
              },
            }}
          >
            <a href="/"> Health For Longevity </a>
          </Typography>
          <IconButton
            edge="end"
            color="black"
            aria-label="menu"
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{ style: { width: "70vw", backgroundColor: "white" } }}
      >
        <div>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setDrawerOpen(false)}
            style={{
              float: "right",
              margin: "0rem",
              padding: "0.5rem 0.5rem 0rem 0rem",
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <List style={{ lineHeight: "1rem", padding: "0.25rem 1rem" }}>
          <ListItem>
            <ListItemText
              primary="SiteMap"
              style={{
                color: "#3a9e95",
                fontSize: "1.5rem",
                fontWeight: "bold",
                letterSpacing: "0.3rem",
                fontSize: "1rem",
                textTransform: "uppercase",
              }}
            />
          </ListItem>
          {mainLinks.map((link) => (
            <ListItem button key={link.label} component={Link} to={link.to}>
              <ListItemText primary={link.label} style={{ color: "black" }} />
            </ListItem>
          ))}
          <Divider />
          {/* <ListItem style={{ padding: "0.25rem 1rem" }}></ListItem>
          <ListItem>
            <ListItemText
              primary="Social Media"
              style={{
                color: "#3a9e95",
                fontSize: "1.5rem",
                fontWeight: "bold",
                letterSpacing: "0.3rem",
                fontSize: "1rem",
                textTransform: "uppercase",
              }}
            />
          </ListItem>
          {socialLinks.map((social) => (
            <ListItem button key={social.label} component={Link} to={social.to}>
              <ListItemText primary={social.label} style={{ color: "black" }} />
            </ListItem>
          ))} */}
        </List>
        <DrawerFooter>
          <div style={{ padding: "1rem" }}>
            <div style={{ marginTop: "1rem" }}>
              <a
                href="mailto:william.han@unitedhealthcentres.com"
                style={{ color: "#3a9e95" }}
              >
                william.han@unitedhealthcentres.com
              </a>
            </div>
            <div>
              <a href="tel:780-761-9111" style={{ color: "#3a9e95" }}>
                780-761-9111
              </a>
            </div>
            <br />
            <a
              href="https://maps.app.goo.gl/essQvh8H8tWTYyeD9"
              style={{ color: "#3a9e95" }}
            >
              <div>
                7609 109 St NW,
                <br />
                Edmonton, AB
                <br />
                T6G 1C3
              </div>
            </a>
          </div>
        </DrawerFooter>
      </Drawer>
    </div>
  );
}
