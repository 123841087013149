import { NavLink, useLocation } from "react-router-dom";
import "./Header.scss";
import Logo from "../../assets/images/LOGO.png";

const Dropdown = ({ label, items, isActive, noDropdown, className }) => (
  <div className={`dropdown ${className}`}>
    {noDropdown ? (
      <NavLink
        to="/membership"
        className={`header__link ${isActive ? "active" : ""}`}
      >
        <span className="header__item">{label}</span>
      </NavLink>
    ) : (
      <>
        <span className={`header__link ${isActive ? "active" : ""}`}>
          <span className="header__item">{label}</span>
        </span>
        <div className="dropdown--content">
          {items.map((item) => (
            <NavLink key={item.to} to={item.to} className="header__link">
              <span className="header__item-service">{item.label}</span>
            </NavLink>
          ))}
        </div>
      </>
    )}
  </div>
);

const menuItems = {
  aboutUs: [
    { to: "/mission", label: "Our Mission" },
    { to: "/ourteam", label: "Our Team" },
  ],
  others: [{ to: "/construction", label: "Coming Soon" }],
};

export default function Header() {
  const location = useLocation();

  const checkActive = (items) =>
    items.some((item) => item.to === location.pathname);

  return (
    <nav className="nav">
      <div className="header">
        <div className="header__left">
          <NavLink to="/" className="header__home">
            <img src={Logo} className="header__logo" />
          </NavLink>
        </div>
        <div className="header__right">
          <div className="header__menu">
            <NavLink className="header__link" to="/about-us">
              <h3 className="header__link header__menu--item dropdown">
                About Us
              </h3>
            </NavLink>
            <Dropdown
              label="Events"
              items={menuItems.others}
              isActive={checkActive(menuItems.others)}
              className="header__menu--item"
            />
            <Dropdown
              label="Exhibitors"
              items={menuItems.others}
              isActive={checkActive(menuItems.others)}
              className="header__menu--item"
            />
            <Dropdown
              label="Resources"
              items={menuItems.others}
              isActive={checkActive(menuItems.others)}
              className="header__menu--item"
            />
            <div className="membership_bottom">
              <div className="membership_btn-container">
                <NavLink to="/membership">
                  <h3 className="membership_btn">Membership</h3>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
