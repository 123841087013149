import { useState } from "react";
import { useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { useMediaQuery, useTheme } from "@mui/material";

import "./FAQ.scss";

export default function FAQ() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md")); // 'md' represents the breakpoint for screens 960px and up, commonly associated with tablets and larger devices.

  const handleExpandClick = () => {
    if (expanded.includes(false)) {
      // If any accordion is collapsed, expand all
      setExpanded(Array(faqs.length).fill(true));
    } else {
      // Otherwise, collapse all
      setExpanded(Array(faqs.length).fill(false));
    }
  };

  const faqs = [
    {
      question: "What is Health for Longevity (H4L)?",
      answer:
        "H4L is a membership-based platform providing healthcare professionals with seminars, events, and resources to promote longevity in their practices and personal health.",
    },
    {
      question: "Who can join H4L?",
      answer:
        "H4L is open to physicians, health practitioners, and anyone with a interest in health and longevity.",
    },
    {
      question: "What does the membership include?",
      answer:
        "The membership includes access to our exclusive directory, educational discounts, seminar and event access, networking opportunities, and more.",
    },
    {
      question: "Is there a trial period for new members?",
      answer:
        "Yes, we currently offer an year long complimentary membership for a limited time to let new members experience the full benefits of H4L without any cost.",
    },
    {
      question: "How do I sign up?",
      answer:
        "You can sign up through our website by filling out the membership form and completing the registration process.",
    },
    {
      question: "Can I cancel my membership at any time?",
      answer:
        "Yes, you can cancel your membership at any time. Please refer to our cancellation policy for details on the process.",
    },
    {
      question: "Are the seminars and events available online?",
      answer:
        "Yes, we offer both in-person and online events to accommodate members from all locations.",
    },
    {
      question: "Can I contribute to the H4L platform or events? ",
      answer:
        "We welcome contributions from our members! Please contact us to discuss how you can share your expertise.",
    },
  ];
  const [expanded, setExpanded] = useState(Array(faqs.length).fill(false));

  const generateStructuredData = (faqs) => {
    return {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: faqs.map((faq) => ({
        "@type": "Question",
        name: faq.question,
        acceptedAnswer: {
          "@type": "Answer",
          text: faq.answer,
        },
      })),
    };
  };

  return (
    <div className="faq">
      <h3 className="faq__header">FAQ</h3>
      <div className="buttonContainer">
        <Button
          onClick={handleExpandClick}
          variant="text"
          style={{ color: "white" }}
          edge="end"
        >
          {expanded.includes(false) ? "Expand All" : "Collapse All"}
        </Button>
      </div>
      {faqs.map((faq, index) => (
        <Accordion
          expanded={expanded[index]}
          key={index}
          style={{ background: "transparent" }}
          onChange={() => {
            // Toggle individual accordion items
            const newExpanded = [...expanded];
            newExpanded[index] = !newExpanded[index];
            setExpanded(newExpanded);
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon style={{ color: "rgba(255, 222, 137)" }} />
            }
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography
              className="faq__text"
              style={{
                color: "rgba(255, 222, 137)",
                fontSize: isDesktop ? "1.5rem" : "1rem",
              }}
            >
              {faq.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              className="faq__text"
              style={{
                color: "white",
                fontSize: isDesktop ? "1.5rem" : "1rem",
              }}
            >
              {faq.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
