import React, { useState } from "react";
import "./Newsletter.scss";
import axios from "axios";

const NewsletterSubscription = () => {
	const [email, setEmail] = useState("");
	const [emailWarning, setEmailWarning] = useState("");

	const emailIsValid = (email) => {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regex.test(email);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!emailIsValid(email)) {
			setEmailWarning("Please enter a valid email address.");
			return;
		}

		try {
			const response = await axios.post(
				`${process.env.REACT_APP_BACKEND_URL}emaillist`,
				{
					email: email,
				}
			);
			alert("Subscription successful!");
			setEmail("");
			setEmailWarning("");
		} catch (error) {
			console.error("Form submission failed:", error);
			// Display an error message based on the response status code or error message
			let errorMessage = "Form submission failed. Please try again later.";
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				errorMessage = error.response.data.message || errorMessage;
			} else if (error.request) {
				// The request was made but no response was received
				errorMessage = "No response from the server. Please Try Again Later";
			}
			alert(errorMessage);
		}
	};

	return (
		<div className="newsletter">
			<h2 className="newsletter__title">Subscribe to our Newsletter</h2>
			<p className="newsletter__subtitle">
				Join our community to receive the latest news and updates from our team.
			</p>
			<form className="newsletter__input-group" onSubmit={handleSubmit}>
				<input
					type="email"
					placeholder="Enter your email address"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<button type="submit">SUBMIT</button>
				{emailWarning && <div className="warning">{emailWarning}</div>}
			</form>
		</div>
	);
};

export default NewsletterSubscription;
