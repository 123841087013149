import "./AboutUs.scss";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GroupIcon from "@mui/icons-material/Group";
import SchoolIcon from "@mui/icons-material/School";
import HealingIcon from "@mui/icons-material/Healing";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
export default function AboutUs() {
  const people = [
    {
      Icon: EmojiPeopleIcon,
      name: "Dr. William Han",
      bio: "Dr. William Han's journey in medicine began in China, where he trained as a Medical Oncologist in 1999. His passion for healing led him to Canada, where he became a certified MD by the Medical Council of Canada in 2003 after passing the MCCQE. He's been a respected member of the College of Physicians & Surgeons in British Columbia and Alberta since 2004. An advocate for education, Dr. Han has dedicated the past decade to medical research and teaching, with a special focus on integrated medicine at TCM schools throughout Canada. As the esteemed Medical Director at Edmonton's United Health Centres, he has committed over 20 years to Family Medicine. Dr. Han's vision for Health for Longevity (H4L) is a testament to his lifelong mission: ensuring everyone has the opportunity to live a long, healthy, and fulfilling life, striving for the golden age of 99.",
    },
    // ... add more profiles here
  ];

  const services = [
    {
      Icon: (
        <AccessTimeIcon
          className="about__icon"
          sx={{ fontSize: 50, marginBottom: "1rem" }}
        />
      ),
      title: "Promote Longevity",
      description:
        "Advancing health practices to enhance life quality and span.",
    },
    {
      Icon: (
        <GroupIcon
          className="about__icon"
          sx={{ fontSize: 50, marginBottom: "1rem" }}
        />
      ),
      title: "Build Community",
      description:
        "Connecting healthcare professionals globally for collaborative growth.",
    },
    {
      Icon: (
        <SchoolIcon
          className="about__icon"
          sx={{ fontSize: 50, marginBottom: "1rem" }}
        />
      ),
      title: "Educate",
      description: "Providing cutting-edge educational resources and seminars.",
    },
    {
      Icon: (
        <HealingIcon
          className="about__icon"
          sx={{ fontSize: 50, marginBottom: "1rem" }}
        />
      ),
      title: "Innovation",
      description:
        "Supporting research and innovation in preventative healthcare.",
    },
  ];

  return (
    <div className="about">
      <div className="about__hero">
        <h1 className="about__title">
          About <span className="span__green">Health for Longevity</span>
        </h1>
        <h2 className="about__subtitle">
          Empowering a Global Community for a Healthier Tomorrow
        </h2>
      </div>
      <div className="about__section">
        <h2 className="about__section--title">Our Reach and Mission</h2>
        <p className="about__section--content">
          Health for Longevity (H4L) is a global initiative dedicated to the
          advancement of health and wellness practices to enhance the quality
          and duration of human life. Our international community of healthcare
          professionals is committed to fostering interdisciplinary
          collaborations, promoting innovative medical approaches, and sharing
          culturally diverse perspectives on longevity. Through our educational
          programs, research support, and advocacy, we aim to inspire and
          empower both practitioners and the public to embrace proactive,
          preventive healthcare models that extend healthspan across all stages
          of life.
        </p>
      </div>
      <div className="about__section">
        <h2 className="about__section--title">What We Do</h2>
        <div className="about__list">
          {services.map((service, index) => (
            <li className="about__list--item" key={index}>
              {service.Icon}
              <h4 className="about__list--title">{service.title}:</h4>
              <p className="about__list--benefits">{service.description}</p>
            </li>
          ))}
        </div>
      </div>
      <div className="about__section">
        <h2 className="about__section--title">Our Team</h2>
        <div className="people">
          {people.map((person, index) => (
            <div className="person" key={index}>
              <div className="person__left">
                <person.Icon className="person__icon" />
                <h3 className="person__name">{person.name}</h3>
              </div>

              <p className="person__bio">{person.bio}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
