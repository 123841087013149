import ScrollToTop from "./component/scrollToTop";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./component/Header/Header.js";
import HomePage from "./pages/HomePage/HomePage";
import Membership from "./pages/Membership/Membership.js";
import UnderConstruction from "./pages/Construction/Construction";
import MobileNavbar from "./component/MobileNavbar/MobileNavbar.js";
import AboutUs from "./pages/AboutUs/AboutUs.js";
import Footer from "./component/Footer/Footer";
import SignUpForm from "./pages/Form/Form";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <MobileNavbar />
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/membership" element={<Membership />} />
        <Route path="/construction" element={<UnderConstruction />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/signup" element={<SignUpForm />} />
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
